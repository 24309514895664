import AccountTemplate from "@shared/account/accountTemplate";
import OrderObject from "@shared/account/orderComponents";
import { useStore } from "@state/store";
import { isBrowser } from "@util/helper";
import { useGetOrderLog } from "@util/hooks";
import { GridContainer, Container, Section, HeaderWrapper, PageWidth } from "@util/standard";
import React from "react";

const OrderLog = () => {
  if (!isBrowser()) return null;
  const { user, orderLog } = useStore();
  const isAdmin = user?.isAdmin;
  useGetOrderLog();

  return (
    <AccountTemplate handle="order-log">
      <Section isTop>
        <PageWidth>
          <HeaderWrapper>
            <Container flexFlow="column nowrap">
              <Container margin="0 0 20px">
                <h1>Order Log</h1>
              </Container>
              <p>
                Welcome {user?.firstName} {user?.lastName}
              </p>
            </Container>
          </HeaderWrapper>
          <Container>
            {orderLog && isAdmin ? (
              <GridContainer width="100%" gridTemplateColumns="1fr" gap="10px">
                {orderLog.length > 0 ? (
                  orderLog.map(order => (
                    <OrderObject key={order.uuid} order={order} setOrders={null} isAdmin />
                  ))
                ) : (
                  <>No orders found</>
                )}
              </GridContainer>
            ) : (
              <Container flexFlow="column nowrap" width="100%" margin="35px 0" alignItems="center">
                <p>Oops, you come to the wrong place. Please navigate back home here</p>
              </Container>
            )}
          </Container>
        </PageWidth>
      </Section>
    </AccountTemplate>
  );
};

export default OrderLog;

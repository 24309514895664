import { Button } from "@global";
import { Container, GridContainer, HeaderWrapper, PageWidth, Section } from "@util/standard";
import React, { ReactNode, useEffect, useRef, useState } from "react";

import { AccountHandles } from "@util/types";
import { navigate } from "gatsby";
import { useOnClickOutside } from "@util/hooks";
import { useStore } from "@state/store";

interface Props {
  children: ReactNode;
  handle: AccountHandles;
  title?: string;
}

const AccountTemplate = ({ children, handle, title = "My Homesell" }: Props) => {
  const { user, logout } = useStore();

  const [filterOpen, setFilterOpen] = useState(false);
  const filterEl = useRef(null);

  useOnClickOutside(filterEl, () => setFilterOpen(false));

  const handleLogout = () => {
    navigate("/");
    logout();
  };

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  return (
    <Section isTop>
      <PageWidth>
        <HeaderWrapper>
          <Container flexFlow="column nowrap" maxWidth="60%">
            <Container margin="0 0 20px">
              <h1>{title}</h1>
            </Container>
            <h5>
              Welcome {user?.firstName} {user?.lastName}. Click on your property below to add more
              details or buy a package
            </h5>
          </Container>
          <Container style={{ gap: "10px" }}>
            <Button theme="base" internalSlug="/sell" linkText="Add Another Property" />
            <Button theme="baseOutline" onClick={handleLogout} linkText="Logout" />
          </Container>
        </HeaderWrapper>
      </PageWidth>

      <GridContainer gap="35px" gridTemplateColumns={"1fr"}>
        <Container flexFlow="column nowrap">{children}</Container>
      </GridContainer>
    </Section>
  );
};

export default AccountTemplate;

import { Button, Drawer, Link, Note, PropertyOnboardingFormTabbed } from "@global";
import { Container, GridContainer } from "@util/standard";
import { MOBILE_BREAKPOINT, colorsRGB } from "@util/constants";
import { Order, PackageProp } from "@state/types";
import React, { Dispatch, SetStateAction, useState } from "react";
import { em, toDate } from "@util/helper";

import styled from "styled-components";
import { useStore } from "@state/store";

export const OrderWrapper = styled.div<{ width?: string; margin?: string }>`
  ${({ width }) => width && `width: ${width};`}

  border: 2px solid ${colorsRGB.main(0.2)};
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  padding: 20px 35px;
  background: ${colorsRGB.main(0.05)};
  ${props => props.margin && `margin: ${props.margin};`}

  .id {
    font-size: ${em(14)};
  }

  hr {
    width: calc(100% + 70px);
    margin: 20px -35px;
    border-top: 1px solid ${colorsRGB.main(0.2)};
  }

  .row {
    grid-gap: 35px;
    grid-template-columns: calc(100% - 200px - 35px) 200px;
  }

  .column1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    .h5 {
      margin-bottom: 0;
    }

    h6 {
      margin-bottom: 5px;
    }
  }

  .customised-item {
    font-size: ${em(14)};
    display: flex;
  }

  .column2 {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;

    button {
      font-size: ${em(12)};
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .row {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
    .column2 {
      align-items: flex-start;
      button {
        font-size: ${em(14)};
      }
    }
  }
`;

interface OrderProps {
  order: Order;
  isAdmin?: boolean;
  setOrders: Dispatch<SetStateAction<Order[] | null>>;
}

interface LineItemProps {
  order: Order;
  line: PackageProp;
  index: number;
  isAdmin?: boolean;
  setOrders: Dispatch<SetStateAction<Order[] | null>>;
}

const LineItemObject = ({ order, line, index, setOrders, isAdmin }: LineItemProps) => {
  const [visible, setVisible] = useState(false);
  const [assignUser, setAssignUser] = useState(false);
  const packageId = `${order.uuid}`;
  const completed = Boolean(line.propertyId);

  const { user } = useStore();

  console.log({ line });

  return (
    <>
      <GridContainer className="row">
        <Container className="column1">
          <span className="h5 capitalize">
            Homesell {line.type} Package - {line.tier}
          </span>
          <span>Total Price: ${line.totalPrice}</span>
          {line.customised && (
            <Container flexFlow="column nowrap" margin="20px 0 0">
              <h6>Package inclusions</h6>
              {line.items &&
                line.items.map((item, j) => (
                  <div className="customised-item" key={`${packageId}-${j}`}>
                    <span>
                      {item.title} x {item.quantity}
                      {item.variant && <> - {item.variant.title}</>}
                      {` `}
                      {/* &ndash; Total: <b>${item.totalPrice}</b> */}
                    </span>
                  </div>
                ))}
            </Container>
          )}
        </Container>
        <Container className="column2">
          {!isAdmin && (
            <>
              <Button
                internalSlug={`/sell?packageID=${packageId}`}
                linkText={completed ? "Property Submitted" : "Add property details"}
                disabled={completed}
                state={{ packageId }}
              />
              <Button
                margin="10px 0 0 0 "
                internalSlug="/contact"
                linkText="Change order"
                theme="baseOutline"
              />
            </>
          )}
        </Container>
      </GridContainer>
      <Container>
        {isAdmin && <Note notes={line?.admin?.notes} order={order} line={line} index={index} />}
      </Container>
      {index !== order.packages.length - 1 && <hr />}

      <Drawer
        isCart
        visible={visible}
        onClose={() => setVisible(false)}
        isCustom
        placement="bottom"
        width={assignUser ? "50%" : "100%"}
      >
        {assignUser ? <Container>//TODO: Add search</Container> : <></>}
      </Drawer>
    </>
  );
};

const OrderObject = ({ order, setOrders, isAdmin }: OrderProps) => {
  const date = `${toDate(order.createdAt, "date")} ${toDate(order.createdAt, "month")} ${new Date(
    order.createdAt,
  ).getFullYear()}, ${toDate(order.createdAt, "time")}`;

  console.log({ order });

  return (
    <OrderWrapper>
      <Container flexFlow="column nowrap">
        <GridContainer className="row">
          <Container className="column1">
            {/* <span className="id">Order ID: {order.uuid}</span> */}
            <span className="id">Order Placed: {date}</span>
          </Container>
          <Container className="column2">
            <span className="h4">${order.totalPrice}</span>
          </Container>
        </GridContainer>
        <hr />
        {order.packages.map((line: PackageProp, i) => (
          <LineItemObject
            key={`${order.uuid}-${i}`}
            line={line}
            order={order}
            index={i}
            setOrders={setOrders}
            isAdmin={isAdmin}
          />
        ))}
      </Container>
    </OrderWrapper>
  );
};

export default OrderObject;
